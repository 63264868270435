import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import MMPricing from "../../components/pages/modemagic/Pricing";
import * as styles from "./styles.module.scss";

const PricingCard = ({
  data: {
    heading,
    subHeading,
    description,
    features,
    price: priceValue,
    usage = [],
    isRecommended: featured,
    cta: {link, type, text: ctaText} = {},
  } = {},
}) => {
  const styler = new StyleExtractor(styles);
  let cta = <span />;
  if (type === "link") {
    cta = (
      <OutboundLink href={link} className='price-cta block py-2 mt-4'>
        {ctaText}
      </OutboundLink>
    );
  } else if (type === "talk") {
    cta = (
      <span
        className='price-cta block py-2 mt-4'
        onClick={() => {
          typeof window.Intercom === "function" && window.Intercom("show");
        }}
      >
        {ctaText}
      </span>
    );
  }
  return (
    <div
      className={styler.get("masonPC", [
        "price-card h-full w-full md:w-full m-4 md:mx-0 mx-0 p-7 flex flex-col justify-between items-center",
        featured && "pro-tier",
      ])}
    >
      <div className='price-plan text-center px-4'>
        <h2 className={`planName ${heading.toLowerCase()}`}>{heading}</h2>
        <p className='planDesc'>{subHeading}</p>
        <h4 className='mainFeat'>{description}</h4>
      </div>
      <div className='price-content text-center'>
        <div className='price-plan-feats text-left my-4'>
          <div className='text-center'>
            <h4 className='plaFeat'>{features}</h4>
          </div>
        </div>
      </div>
      <div className='price-details text-center flex flex-col'>
        <div className='caption md:mt-4 md:mb-0.5'>Starting at</div>
        <h3 className='priceVal mb-14 mb-8'>{priceValue}</h3>
        <p className='extraDesc'>
          {usage.map((u) => (
            <div>{u}</div>
          ))}
        </p>
        {cta}
      </div>
    </div>
  );
};

const PricingPage = ({
  data: {
    sanityMasonPricingPlans: {
      _rawDescription: _rawSubHeading,
      heading,
      plans: plan,
    } = {},
    ...cmsData
  },
}) => {
  const styler = new StyleExtractor(styles);
  const seoData = cmsData.allSanitySeoMeta.nodes[0];

  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: "Pricing | Mason",
          items: [
            {
              name: "Pricing | Mason",
              item: "https://getmason.io/pricing",
            },
          ],
        }}
      />
      <div className={styler.get("container", "full-bleed")}>
        <MMPricing
          theme={{
            heading: styles.heading,
          }}
          data={{
            heading,
            _rawSubHeading,
            plan,
          }}
          haveHeadingOne
          PricingCard={PricingCard}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "mason-pricing"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    sanityMasonPricingPlans(_id: {eq: "masonPricingPlans"}) {
      heading
      plans {
        heading
        features
        description
        isRecommended
        price
        subHeading
        usage
        _key
        cta {
          link
          text
          type
        }
      }
      _rawDescription
    }
  }
`;

export default PricingPage;
