import React from "react";

import {useMediaQuery} from "../../hooks/useMediaQuery";
import "./HeadTwo.scss";

const HeadTwo = ({
  children,
  otherContent,
  classes = "",
  showOtherContent,
  haveHeadingOne,
}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const Heading = haveHeadingOne ? "h1" : "h2";
  return (
    <Heading
      className={`headtwo-wrap block sm:flex flex-row items-center justify-center mb-4 medium relative ${classes}`}
    >
      <span className={`text-center ${isMobile ? "mobile" : "desktop"}`}>
        {children}
      </span>
      {showOtherContent && <>{otherContent}</>}
    </Heading>
  );
};

export default HeadTwo;
