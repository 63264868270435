import {isUndefined} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";
import Slider from "react-slick";

import Arrow from "../../../../assets/Arrow.svg";
import actions from "../../../../lib/actions";
import HeadTwo from "../../../HeadTwo/HeadTwo";
import SanityRichText from "../../../commons/SanityRichText";
import "./styles.scss";

const PricingCard = ({
  data: price,
  hideProductInfo = false,
  hideCTA = false,
}) => {
  const {
    name,
    mainFeat: mainFeature,
    otherFeat: features,
    price: priceValue,
    prodCount,
    secondLine,
    featured,
    ctaText,
    showContact,
  } = price ?? {};
  return (
    <div
      className={`price-card h-full w-full md:w-full m-4 md:mx-0 mx-0 p-7 flex flex-col justify-between items-center ${
        featured && "pro-tier"
      }`}
      id='modemagic-pricing'
    >
      <div className='price-plan text-center px-4'>
        <h2 className={`planName ${name}`}>{name}</h2>
        <p className='planDesc'>{secondLine}</p>
        <h4 className='mainFeat'>{mainFeature}</h4>
      </div>
      <div className='price-content text-center'>
        <div className='price-plan-feats text-left my-4'>
          {features.map((f) => (
            <div className='text-center'>
              <h4 className='plaFeat'>{f}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className='price-details text-center flex flex-col'>
        <div className={`caption mt-6 ${showContact && "md:mt-4 md:mb-0.5"}`}>
          Starting at
        </div>
        <h3 className={`priceVal mb-14 ${showContact && "mb-8"}`}>
          ${priceValue}/month
        </h3>
        {!hideProductInfo && (
          <>
            <p className='extraDesc'>
              Upto {prodCount} products included.
              {showContact && (
                <>
                  <br /> For large catalogs.{" "}
                  <OutboundLink
                    onClick={(e) => {
                      e.preventDefault();
                      typeof window.Intercom === "function" &&
                        window.Intercom("show");
                    }}
                  >
                    <b className='talkToUs'>Talk to us</b>
                  </OutboundLink>
                </>
              )}
            </p>
          </>
        )}
        {!hideCTA && (
          <div
            onClick={actions.mmInstallApp}
            className='price-cta block py-2 mt-4'
            id='get-the-app'
          >
            {ctaText}
          </div>
        )}
      </div>
    </div>
  );
};

const MutatedArrow = ({className = "", onClick, prev = false}) => {
  return (
    <Arrow
      className={`${prev ? "prevArrow" : "nextArrow"} ${className}`}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  touchMove: true,
  arrows: false,
  autoplay: false,
  nextArrow: <MutatedArrow />,
  prevArrow: <MutatedArrow prev />,
  initialSlide: 1,
  appendDots: (dots) => (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ul style={{margin: "0px"}} className='dots'>
        {dots}
      </ul>
    </div>
  ),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: true,
      },
    },
  ],
};

const PricingCards = ({plans, PricingCard: PricingCardComponent, ...props}) => {
  const FinalPricingCard = !isUndefined(PricingCardComponent)
    ? PricingCardComponent
    : PricingCard;
  return (
    <Slider {...settings}>
      {plans.map(({_key: key, ...plan}) => (
        <FinalPricingCard key={key} data={plan} {...props} />
      ))}
    </Slider>
  );
};

const MMPricing = ({
  data,
  description,
  PricingCard,
  theme = {},
  haveHeadingOne = false,
  ...props
}) => {
  const {heading, _rawSubHeading, plan: plans} = data;

  return (
    <div id='pricing' className='pricing-wrap full-bleed layouted mt-12 mb-20'>
      <HeadTwo classes={theme.heading} haveHeadingOne={haveHeadingOne}>
        {heading}
      </HeadTwo>
      <SanityRichText blocks={_rawSubHeading} className='pricingSub' />
      <PricingCards plans={plans} PricingCard={PricingCard} {...props} />
    </div>
  );
};

export default MMPricing;
